import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import msDos from './windows/msdosUm.png';
import windowsum from './windows/windowsum.png';
import windowsdois from './windows/windowsdois.png';
import windowstres from './windows/windowstres.png';
import windowsnt from './windows/windowsnt.png';
import windowsnoventaecinco from './windows/windowsnoventaecinco.png';
import windowsnoventaeoito from './windows/windowsnoventaeoito.png';
import windowsdoismil from './windows/windowsdoismil.png';
import windowsxp from './windows/windowsxp.png';
import windowsvista from './windows/windowsvista.png';
import windowssete from './windows/windowssete.png';
import windowsoito from './windows/windowsoito.png';
import windowsdez from './windows/windowsdez.png';
import windowsonze from './windows/windowsonze.png';

import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';
import { DivImgCenter } from './styles';


function ColegioPlanoVersoesWindows() {
    const navigate = useNavigate();


    return (
        <div>
            <Sidebar>
                <div>
                    <h1 style={{ margin: '0', padding: '0' }}>Versões do Windows</h1>
                    <h3 style={{ margin: '0', padding: '0' }}>Desde 1981 até 2024</h3>
                </div>
                <p>Aqui está uma introdução e três características de cada uma das principais versões do Windows, desde o MS-DOS até o Windows 11:</p>

                <section>
                    <h3>MS-DOS (1981)</h3>
                    <p><b>Introdução:</b> Sistema operacional de linha de comando criado pela Microsoft e usado como base para o Windows.</p>
                    <h4>Características:</h4>
                    <ul>
                        <li>Interface de linha de comando sem GUI.</li>
                        <li>Controle direto de hardware com comandos manuais.</li>
                        <li>Suporte limitado para multitarefa.</li>
                    </ul>
                    <DivImgCenter>
                        <img src={msDos} alt="msDos" />
                    </DivImgCenter>
                </section>

                <section>
                    <h3>Windows 1.0 (1982-1985)</h3>
                    <p><b>Introdução: </b>Primeira versão do Windows, projetada como uma interface gráfica para MS-DOS.</p>
                    <h4>Características:</h4>
                    <ul>
                        <li>Janelas sobrepostas para multitarefa básica.</li>
                        <li>Interface baseada em menus e ícones.</li>
                        <li>Aplicações simples como Paint e Calculadora.</li>
                    </ul>
                    <DivImgCenter>
                        <img src={windowsum} alt="Windows 1.0" />
                    </DivImgCenter>
                </section>

                <section>
                    <h3>Windows 2.0 e 2.11 (1987-1990)</h3>
                    <p><b>Introdução: </b>Evolução do Windows 1.0, com melhorias gráficas e suporte a mais programas.</p>
                    <h4>Características:</h4>
                    <ul>
                        <li>Suporte a sobreposição de janelas e atalhos de teclado.</li>
                        <li>Introdução de aplicativos como Excel e Word.</li>
                        <li>Melhor integração com MS-DOS.</li>
                    </ul>
                    <DivImgCenter>
                        <img src={windowsdois} alt="Windows 2.0" />
                    </DivImgCenter>
                </section>

                <section>
                    <h3>Windows 3.0 (1990)</h3>
                    <p><b>Introdução: </b>Lançado em 1990, o Windows 3.0 foi um grande marco para a Microsoft,
                        com melhorias significativas em termos de desempenho gráfico e usabilidade.
                    </p>
                    <h4>Características:</h4>
                    <ul>
                        <li>Interface gráfica com ícones coloridos e menus mais intuitivos.</li>
                        <li>Melhor gerenciamento de memória, permitindo rodar programas maiores.</li>
                        <li>Suporte multitarefa cooperativa, permitindo rodar vários programas ao mesmo tempo.</li>
                    </ul>
                    <DivImgCenter>
                        <img src={windowstres} alt="Windows 3.0" />
                    </DivImgCenter>
                </section>


                <section>
                    <h3>Windows NT (1993)</h3>
                    <p><b>Introdução: </b>O Windows NT (New Technology) foi lançado em 1993, focado principalmente no mercado corporativo
                        e de servidores, sendo a primeira versão do Windows construída em um kernel de 32 bits.
                    </p>
                    <h4>Características:</h4>
                    <ul>
                        <li>Arquitetura totalmente nova e independente do MS-DOS, voltada para maior estabilidade e segurança.</li>
                        <li>Suporte a múltiplos processadores, ideal para ambientes empresariais.</li>
                        <li>Sistema de arquivos NTFS, que oferecia melhor desempenho, segurança e suporte a grandes volumes de dados.</li>
                    </ul>
                    <DivImgCenter>
                        <img src={windowsnt} alt="Windows NT" />
                    </DivImgCenter>
                </section>


                <section>
                    <h3>Windows 95 (1995-1997)</h3>
                    <p><b>Introdução: </b>Revolucionário, combinou MS-DOS com uma interface gráfica mais sofisticada,
                        incluindo o novo Menu Iniciar.</p>
                    <h4>Características:</h4>
                    <ul>
                        <li>Introdução do Menu Iniciar e Barra de Tarefas.</li>
                        <li>Suporte a Plug and Play para facilitar a instalação de hardware.</li>
                        <li>Integração inicial com a Internet e redes locais.</li>
                    </ul>
                    <DivImgCenter>
                        <img src={windowsnoventaecinco} alt="Windows 95" />
                    </DivImgCenter>
                </section>

                <section>
                    <h3>Windows 98 (1998)</h3>
                    <p><b>Introdução: </b>Evolução do Windows 95, trazendo melhorias de desempenho e integração com a web.</p>
                    <h4>Características:</h4>
                    <ul>
                        <li>Suporte ao sistema de arquivos FAT32.</li>
                        <li>Maior integração com a Internet, incluindo o Internet Explorer.Revolucionário, combinou MS-DOS com uma interface
                            gráfica mais sofisticada, incluindo o novo Menu Iniciar.</li>
                        <li>Suporte a dispositivos USB e drives removíveis.</li>
                    </ul>
                    <DivImgCenter>
                        <img src={windowsnoventaeoito} alt="Windows 98" />
                    </DivImgCenter>
                </section>


                <section>
                    <h3>Windows Me e Windows 2000 (1998-2000)</h3>
                    <h4>Windows Me (Millennium Edition):</h4>
                    <p><b>Introdução: </b>Focado no usuário doméstico, foi o último baseado no kernel Windows 9x.</p>
                    <h4>Características:</h4>
                    <ul>
                        <li>Sistema de restauração integrado para recuperação de erros.</li>
                        <li>Melhor suporte multimídia com Windows Media Player.</li>
                        <li>Instabilidade e problemas de compatibilidade.</li>
                    </ul>
                    <h4>Windows Me (Millennium Edition):</h4>
                    <p><b>Introdução: </b>Voltado para ambientes corporativos, construído sobre o kernel NT.</p>
                    <h4>Características:</h4>
                    <ul>
                        <li>Sistema operacional robusto e seguro.</li>
                        <li>Suporte a redes corporativas e internet.</li>
                        <li>Estabilidade melhorada para servidores e estações de trabalho.</li>
                    </ul>


                    <DivImgCenter>
                        <img src={windowsdoismil} alt="Windows 2000" />
                    </DivImgCenter>
                </section>

                <section>
                    <h3>Windows XP (2001-2005)</h3>
                    <p><b>Introdução: </b>Misturando estabilidade do Windows 2000 com uma interface mais amigável,
                        foi amplamente adotado por consumidores e empresas.</p>
                    <h4>Características:</h4>
                    <ul>
                        <li>Interface redesenhada, mais simples e colorida.</li>
                        <li>Estabilidade graças ao kernel NT.</li>
                        <li>Suporte nativo a redes sem fio e maior compatibilidade com hardware.</li>
                    </ul>
                    <DivImgCenter>
                        <img src={windowsxp} alt="Windows XP" />
                    </DivImgCenter>
                </section>

                <section>
                    <h3>Windows Vista (2006-2008)</h3>
                    <p><b>Introdução: </b>Focado em melhorar a segurança e a aparência visual, mas criticado pelo desempenho.</p>
                    <h4>Características:</h4>
                    <ul>
                        <li>Interface Aero com transparências e efeitos visuais.</li>
                        <li>Controle de Conta de Usuário (UAC) para melhorar a segurança.</li>
                        <li>Requisitos altos de hardware, resultando em má aceitação inicial.</li>
                    </ul>
                    <DivImgCenter>
                        <img src={windowsvista} alt="Windows Vista" />
                    </DivImgCenter>
                </section>


                <section>
                    <h3>Windows 7 (2009-2012)</h3>
                    <p><b>Introdução: </b>Sucesso de público e crítica, trouxe maior eficiência e estabilidade em comparação ao Vista.</p>
                    <h4>Características:</h4>
                    <ul>
                        <li>Melhor gerenciamento de janelas com o recurso "Snap".</li>
                        <li>Barra de tarefas redesenhada e mais intuitiva.</li>
                        <li>Suporte expandido para telas sensíveis ao toque.</li>
                    </ul>
                    <DivImgCenter>
                        <img src={windowssete} alt="Windows 7" />
                    </DivImgCenter>
                </section>


                <section>
                    <h3>Windows 8 e 8.1 (2012-2014)</h3>
                    <p><b>Introdução: </b>Uma tentativa de unificar a experiência entre PCs e dispositivos móveis,
                        mas recebeu críticas pela remoção do Menu Iniciar.</p>
                    <h4>Características:</h4>
                    <ul>
                        <li>Tela Iniciar com blocos dinâmicos (Live Tiles).</li>
                        <li>Integração com a Windows Store para aplicativos.</li>
                        <li>Windows 8.1 trouxe de volta o botão Iniciar e ajustes na interface.</li>
                    </ul>
                    <DivImgCenter>
                        <img src={windowsoito} alt="Windows 8" />
                    </DivImgCenter>
                </section>

                <section>
                    <h3>Windows 10 (2015)</h3>
                    <p><b>Introdução: </b>Sistema projetado para ser a plataforma definitiva, com atualizações
                        contínuas e compatível com múltiplos dispositivos.</p>
                    <h4>Características:</h4>
                    <ul>
                        <li>Retorno do Menu Iniciar com integração de blocos dinâmicos.</li>
                        <li>Atualizações contínuas com o modelo Windows as a Service.</li>
                        <li>Assistente virtual Cortana e suporte a desktops virtuais.</li>
                    </ul>
                    <DivImgCenter>
                        <img src={windowsdez} alt="Windows 10" />
                    </DivImgCenter>
                </section>

                <section>
                    <h3>Windows 11 (2021)</h3>
                    <p><b>Introdução: </b>Uma atualização significativa no design e funcionalidade,
                        com foco em produtividade, conectividade e segurança.</p>
                    <h4>Características:</h4>
                    <ul>
                        <li>Interface renovada com ícones centralizados e bordas arredondadas.</li>
                        <li>Suporte para aplicativos Android via Amazon Appstore.</li>
                        <li>Integração aprimorada com ferramentas de produtividade, como Microsoft Teams.</li>
                    </ul>
                    <DivImgCenter>
                        <img src={windowsonze} alt="Windows 11" />
                    </DivImgCenter>
                </section>


            </Sidebar>
        </div>
    );
}

export default ColegioPlanoVersoesWindows;



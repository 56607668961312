import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useNavigate } from 'react-router-dom';


import Button from '../../../Components/Button/ButtonMaterial';
import Sidebar from '../../../Components/SideBar/MiniDrawer';

function PhpConexao() {
    const navigate = useNavigate();

    const Conexao = `<?php
$dsn = 'mysql:host=localhost;dbname=exemplo_db';
$username = 'root'; // Usuário do MySQL
$password = ''; // Senha do MySQL

try {
    $pdo = new PDO($dsn, $username, $password);
    $pdo->setAttribute(PDO::ATTR_ERRMODE, PDO::ERRMODE_EXCEPTION);
} catch (PDOException $e) {
    echo "Erro: " . $e->getMessage();
    die();
}
?>`;

    const CarregarDados = `<?php
require 'db_connect.php';

try {
    $sql = "SELECT * FROM usuarios";
    $stmt = $pdo->query($sql);

    $result = $stmt->fetchAll(PDO::FETCH_ASSOC);
    foreach ($result as $row) {
        echo "ID: " . $row['id'] . "<br>";
        echo "Nome: " . $row['nome'] . "<br>";
        echo "Email: " . $row['email'] . "<br><br>";
    }
} catch (PDOException $e) {
    echo "Erro ao carregar dados: " . $e->getMessage();
}
?>`;


    const InserirDados = `<?php
require 'db_connect.php';

try {
    $sql = "INSERT INTO usuarios (nome, email) VALUES (:nome, :email)";
    $stmt = $pdo->prepare($sql);

    $nome = 'João Silva';
    $email = 'joao@example.com';
    $stmt->bindParam(':nome', $nome);
    $stmt->bindParam(':email', $email);
    $stmt->execute();

    echo "Registro inserido com sucesso<br>";
} catch (PDOException $e) {
    echo "Erro ao inserir dados: " . $e->getMessage();
}
?>`;

    return (
        <div>
            <Sidebar>
                <h1>Conexão</h1>

                <p><b>db_connect.php:</b> Este arquivo estabelece a conexão com o banco de dados. Ele usa o PDO para conectar ao MySQL
                    e configura a manipulação de erros para lançar exceções. Qualquer script que precise de acesso ao banco de dados incluirá este arquivo.</p>

                <p><b>load_data.php:</b> Este arquivo inclui db_connect.php para obter a conexão PDO e então realiza uma consulta para carregar os dados da tabela usuarios, exibindo-os na página.</p>

                <p><b>insert_data.php:</b>  Este arquivo também inclui db_connect.php para obter a conexão PDO e então prepara e executa uma declaração SQL para inserir um
                    novo registro na tabela usuarios.</p>

                <SyntaxHighlighter language="php" style={darcula}>
                    {Conexao}
                </SyntaxHighlighter>

                <SyntaxHighlighter language="php" style={darcula}>
                    {CarregarDados}
                </SyntaxHighlighter>

                <SyntaxHighlighter language="php" style={darcula}>
                    {InserirDados}
                </SyntaxHighlighter>

            </Sidebar>
        </div>
    );
}

export default PhpConexao;


